import * as t from 'io-ts'

const LinkDecoder = t.type({
  label: t.string,
  url: t.string,
})

export const PolaroidDecoder = t.intersection(
  [
    t.type({ title: t.string }, 'IntersectPolaroid01'),
    t.partial(
      { subTitle: t.string, linkA: LinkDecoder, linkB: LinkDecoder },
      'IntersectPolaroid02'
    ),
  ],
  'Polaroid'
)
